<template>
  <div>
    <el-dialog
      title="活动通知"
      :visible.sync="dialogVisible"
      width="40%"
      :close-on-click-modal="false"
      :show-close="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
    >
      <ul>
        <li v-for="(item, index) in data" :key="index">
          <div class="title">
            <img src="../assets/web2x_zuoye.png" alt="" />
            教学活动
          </div>
          <div class="pBox">
            <b>#作业#</b>
            <b style="color: #3c4043; margin-left: 10px">{{ item.content }}</b>
          </div>
          <div class="fot">
            <span>来自</span>
            <span>{{ item.come_from }}</span>
            <span>{{ item.create_time }}</span>
          </div>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="yesBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { messageRead } from "@/api/order.js";
export default {
  data() {
    return {
      dialogVisible: false,
      data: [],
    };
  },
  methods: {
    show(data) {
      this.dialogVisible = true;
      this.data = data;
    },
    yesBtn() {
      messageRead({a:1}).then((res) => {
        this.handleClose();
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.data = [];
    },
  },
};
</script>
<style lang="scss" scoped>
ul {
  li {
    cursor: pointer;
    padding: 10px 10px;
    box-sizing: border-box;
    border: 1px solid #dadce0;
    margin-bottom: 6px;
    .title {
      display: flex;
      align-items: center;
      color: #575a5b;
      font-size: 18px;
      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
    .pBox {
      font-size: 16px;
      margin-top: 16px;
      color: #4285f4;
    }
    .fot {
      margin-top: 16px;
      font-size: 16px;
      span {
        &:nth-child(1) {
          color: #000000;
        }
        &:nth-child(2) {
          color: #4285f4;
          margin-left: 4px;
        }
        &:nth-child(3) {
          color: #74787c;
          margin-left: 30px;
        }
      }
    }
  }
}
</style>
